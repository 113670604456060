import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faLocationDot,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

export const Footer = () => {
  return (
    <>
      <div className="footer_section bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <h3 className="footer_text josefin-sans-regular">Useful links</h3>
              <div className="footer_menu">
                <ul>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "active josefin-sans-semibold"
                          : "josefin-sans-light"
                      }
                      to="/"
                    >
                      <FontAwesomeIcon icon={faArrowRight} className="mr-2" />
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "active josefin-sans-semibold"
                          : "josefin-sans-light"
                      }
                      to="/about-us"
                    >
                      <FontAwesomeIcon icon={faArrowRight} className="mr-2" />
                      About Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "active josefin-sans-semibold"
                          : "josefin-sans-light"
                      }
                      to="/harvest-process"
                    >
                      <FontAwesomeIcon icon={faArrowRight} className="mr-2" />
                      Honey Process
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "active josefin-sans-semibold"
                          : "josefin-sans-light"
                      }
                      to="/products"
                    >
                      <FontAwesomeIcon icon={faArrowRight} className="mr-2" />
                      Products
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? "active josefin-sans-semibold"
                          : "josefin-sans-light"
                      }
                      to="/contact-us"
                    >
                      <FontAwesomeIcon icon={faArrowRight} className="mr-2" />
                      Contact Us
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <h3 className="footer_text josefin-sans-regular">Address</h3>
              <div className="location_text">
                <ul>
                  <li>
                    <div className="address josefin-sans-regular">
                      <span className="padding_left_10">
                        <FontAwesomeIcon icon={faLocationDot} />{" "}
                      </span>
                      Nizampet, Hyderabad
                      <br /> Telangana, INDIA
                    </div>
                  </li>
                  <li>
                    <div className="address josefin-sans-regular">
                      <span className="padding_left_10">
                        <FontAwesomeIcon icon={faPhone} />
                      </span>
                      (+91) 9949420202
                    </div>
                  </li>
                  <li>
                    <div className="address josefin-sans-regular">
                      <span className="padding_left_10">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </span>
                      support@aranyakastore.in
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <h3 className="footer_text josefin-sans-regular">Find Us</h3>
              <div className="social_icon m-0">
                <div className="d-flex align-items-center">
                  <div className="icon mr-3">
                    <FontAwesomeIcon icon={faFacebook} />{" "}
                  </div>
                  <span className="josefin-sans-regular text-white">
                    aranyakaorganicllp
                  </span>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <div className="icon mr-3">
                    <FontAwesomeIcon icon={faInstagram} />{" "}
                  </div>
                  <span className="josefin-sans-regular text-white">
                    aranyakaorganicllp
                  </span>
                </div>
                <div className="d-flex align-items-center mt-3">
                  <div className="icon mr-3">
                    <FontAwesomeIcon icon={faWhatsapp} />{" "}
                  </div>
                  <span className="josefin-sans-regular text-white">
                    9949420202
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright_section py-2">
        <div className="container">
          <p className="copyright_text josefin-sans-regular">
            2024 All Rights Reserved.{" "}
            <small>
              Developed & Maintaing by{" "}
              {/* <a href="https://html.design">SSR Groups</a> */}
              SSR Groups
            </small>
          </p>
        </div>
      </div>
    </>
  );
};
