import { useDispatch, useSelector } from "react-redux";

import { addToCart, updateCart } from "../redux/slices/cart.slice";

import bigOne from "../assets/images/big.JPG";
import smallOne from "../assets/images/small.JPG";

import "../assets/css/products.css";

export const ProductsPage = () => {
  const cartItems = useSelector((state) => state.cart.cart);
  const dispatch = useDispatch();
  const products = [
    {
      id: 1,
      name: "Organic Honey 300gms",
      image: smallOne,
      price: 225,
      offerPrice: 210,
    },
    {
      id: 2,
      name: "Organic Honey 500gms",
      image: bigOne,
      price: 375,
      offerPrice: 325,
    },
  ];

  const handleToCart = (product) => {
    if (cartItems.length) {
      let objIndex = cartItems.findIndex((val) => val.id === product.id);
      if (objIndex >= 0) {
        const bag = [...cartItems];
        bag[objIndex] = { ...bag[objIndex], qty: bag[objIndex].qty + 1 };
        dispatch(updateCart(bag));
      } else {
        product.qty = 1;
        dispatch(addToCart(product));
      }
    } else {
      product.qty = 1;
      dispatch(addToCart(product));
    }
  };

  return (
    <div className="vagetables_section bg-transparent py-3">
      <div className="container product-wrapper mx-5">
        <div className="courses_section_2">
          <div className="row">
            {products.map((product, index) => (
              <div key={index} className="col-md-4">
                <div className="breadcrumb bg-white hover01 shadow">
                  <figure>
                    <img src={product.image} alt={product.name} />
                  </figure>
                  <h3 className="harshal_text josefin-sans-light">
                    {product.name}
                  </h3>
                  <div className="josefin-sans-regular rate_text">
                    <span className="text-strike text-danger fa-2xs">
                      &#8377;{product.price}
                    </span>
                    <span className="d-inline text-body fa-fade">
                      &nbsp;&#8377;{product.offerPrice}
                    </span>
                  </div>
                  <div className="read_bt_1 w-50 p-0 d-block">
                    <div
                      className="bg-primary josefin-sans-regular cursor-pointer add-to-cart"
                      onClick={() => handleToCart(product)}
                    >
                      Add to Cart
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsPage;
