import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import logo from "../assets/images/site-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBasket, faRemove } from "@fortawesome/free-solid-svg-icons";
import "../assets/css/header.css";
import { updateCart } from "../redux/slices/cart.slice";

export const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cart);

  const handleCart = (product) => {
    if (cartItems.length) {
      const bag = cartItems.filter((val) => val.id !== product.id);
      dispatch(updateCart(bag));
    }
  };

  return (
    <div className="header_section bg-white shadow">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg navbar-light bg-light py-2 px-0">
          <NavLink className="navbar-brand" to="/">
            <img src={logo} alt="logo" style={{ height: "40px" }} />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active josefin-sans-semibold"
                      : "nav-link josefin-sans-regular"
                  }
                  to="/"
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active josefin-sans-semibold"
                      : "nav-link josefin-sans-regular"
                  }
                  to="/about-us"
                >
                  About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active josefin-sans-semibold"
                      : "nav-link josefin-sans-regular"
                  }
                  to="/harvest-process"
                >
                  Honey Process
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active josefin-sans-semibold"
                      : "nav-link josefin-sans-regular"
                  }
                  to="/products"
                >
                  Products
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link active josefin-sans-semibold"
                      : "nav-link josefin-sans-regular"
                  }
                  to="/contact-us"
                >
                  Contact Us
                </NavLink>
              </li>
              <li className="nav-item align-items-center d-flex position-relative p-2 mr-4 basket">
                <FontAwesomeIcon
                  icon={faShoppingBasket}
                  size="lg"
                  color="orange"
                />
                <span className="bg-info text-white position-absolute px-2 mx-2 rounded-circle cart-icon">
                  {cartItems.length}
                </span>
                <div className="shopping-item">
                  {cartItems.length > 0 ? (
                    <>
                      <div className="text-center josefin-sans-regular">
                        {cartItems.length} Item(s)
                      </div>
                      {cartItems.map((product, index) => (
                        <div key={index} className="row border-bottom py-2">
                          <div className="col-4">
                            <img
                              className="img-fluid"
                              style={{ height: "40px" }}
                              src={product.image}
                              alt="..."
                            />
                          </div>
                          <div className="col-6 text-body">
                            <div className="josefin-sans-medium fa-10">
                              {product.name}
                            </div>
                            <div className="d-flex josefin-sans-regular fa-10">
                              <span>{product.qty}&nbsp;x&nbsp;</span>
                              <span>{product.offerPrice}</span>
                            </div>
                          </div>
                          <div
                            className="col-2 align-content-center cursor-pointer"
                            onClick={() => handleCart(product)}
                          >
                            <FontAwesomeIcon
                              icon={faRemove}
                              size="lg"
                              color="red"
                            />
                          </div>
                        </div>
                      ))}
                      <div className="mt-4">
                        <div className="d-flex justify-content-between josefin-sans-semibold text-body">
                          <span>Total</span>
                          <span className="total-amount">
                            &#8377;
                            {cartItems.reduce(
                              (n, { offerPrice, qty }) => n + offerPrice * qty,
                              0
                            )}
                          </span>
                        </div>
                        <button
                          className="p-1 mt-4 w-100 rounded-4 bg-dark text-white josefin-sans-regular"
                          onClick={() => navigate("/checkout")}
                        >
                          Checkout
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="text-center josefin-sans-regular text-success">
                      Your Cart is Empty
                    </div>
                  )}
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};
