import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { AboutPage } from "./pages/about";
import { HomePage } from "./pages/home";
import { HarvestingPage } from "./pages/harvesting";
import { ContactPage } from "./pages/contact";
import { ProductsPage } from "./pages/products";
import { CheckoutPage } from "./pages/checkout";
import ScrollToTop from "./components/scroll-to-top";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      {/* <div style={{ marginBottom: "76px" }}> */}
      <Routes>
        <Route index element={<HomePage />} />
        {/* <Route path="properties" element={<Properties />} />*/}
        <Route path="harvest-process" element={<HarvestingPage />} />
        <Route path="about-us" element={<AboutPage />} />
        <Route path="contact-us" element={<ContactPage />} />
        <Route path="products" element={<ProductsPage />} />
        <Route path="checkout" element={<CheckoutPage />} />
      </Routes>
      {/* </div> */}
      <Footer />
    </BrowserRouter>
  );
}

export default App;
