import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRemove } from "@fortawesome/free-solid-svg-icons";

import "../assets/css/contact.css";
import { updateCart } from "../redux/slices/cart.slice";

export const CheckoutPage = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cart);

  const handleCart = (product) => {
    if (cartItems.length) {
      const bag = cartItems.filter((val) => val.id !== product.id);
      dispatch(updateCart(bag));
    }
  };

  const updateQty = (product, type) => {
    if (cartItems.length) {
      let objIndex = cartItems.findIndex((val) => val.id === product.id);
      if (cartItems[objIndex].qty === 1 && type === "dec") return;
      if (objIndex >= 0) {
        const bag = [...cartItems];
        bag[objIndex] = {
          ...bag[objIndex],
          qty: type === "inc" ? bag[objIndex].qty + 1 : bag[objIndex].qty - 1,
        };
        dispatch(updateCart(bag));
      }
    }
  };

  return (
    <div className="contact_section py-5">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1 className="contact_taital josefin-sans-semibold">
              Checkout Page
            </h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="contact_section_2 pt-3">
          <div className="row">
            <div className="col-md-6">
              <div className="mail_section_1">
                <input
                  type="text"
                  className="mail_text josefin-sans-regular shadow-lg"
                  placeholder="Name"
                  name="Name"
                />
                <input
                  type="text"
                  className="mail_text josefin-sans-regular shadow-lg"
                  placeholder="Phone Number"
                  name="Phone Number"
                />
                <input
                  type="text"
                  className="mail_text josefin-sans-regular shadow-lg"
                  placeholder="Email"
                  name="Email"
                />
                <input
                  type="text"
                  className="mail_text josefin-sans-regular shadow-lg"
                  placeholder="Address Line 1"
                  name="Address1"
                />
                <input
                  type="text"
                  className="mail_text josefin-sans-regular shadow-lg"
                  placeholder="Addres Line 2"
                  name="Address2"
                />
              </div>
            </div>
            <div className="col-md-6">
              {cartItems.length ? (
                <>
                  <div className="text-content">
                    <h1 className="josefin-sans-extrabold text-center">
                      Your Order
                    </h1>
                  </div>
                  {cartItems.map((product, index) => (
                    <div
                      key={index}
                      className="row josefin-sans-regular mb-3 text-body"
                    >
                      <div className="col-5">
                        <div className="d-flex align-items-center">
                          <img
                            className="img-fluid mr-3"
                            style={{ height: "50px" }}
                            src={product.image}
                            alt="..."
                          />
                          <span>{product.name}</span>
                        </div>
                      </div>
                      <div className="col-4 align-content-center">
                        <div className="d-flex h-50">
                          <span
                            className={`cursor-pointer bg-dark align-content-center p-2 text-white ${
                              product.qty === 1 ? "opacity-25" : "opacity-100"
                            }`}
                            onClick={() => updateQty(product, "dec")}
                          >
                            -
                          </span>
                          <input
                            className="w-75 text-center qty-input"
                            value={product.qty}
                            readOnly
                          />
                          <span
                            className="cursor-pointer bg-dark align-content-center p-2 text-white"
                            onClick={() => updateQty(product, "inc")}
                          >
                            +
                          </span>
                        </div>{" "}
                      </div>
                      <div className="col-2 align-content-center">
                        Rs.{product.offerPrice}
                      </div>
                      <div
                        className="col-1 align-content-center cursor-pointer"
                        onClick={() => handleCart(product)}
                      >
                        <FontAwesomeIcon
                          icon={faRemove}
                          size="lg"
                          color="red"
                        />
                      </div>
                    </div>
                  ))}
                  <div className="row col-12 text-body josefin-sans-semibold border-top mt-3 pt-3">
                    <div className="col-10">
                      <div>Subtotal</div>
                    </div>
                    <div className="col-2 align-content-center">
                      Rs.
                      {cartItems.reduce(
                        (n, { offerPrice, qty }) => n + offerPrice * qty,
                        0
                      )}
                    </div>
                  </div>
                  <div className="row col-12 text-body josefin-sans-semibold">
                    <div className="col-10">
                      <div>Shipping</div>
                    </div>
                    <div className="col-2 align-content-center">Rs.0</div>
                  </div>
                  <div className="row col-12 text-body josefin-sans-semibold">
                    <div className="col-10">
                      <div>Total</div>
                    </div>
                    <div className="col-2 align-content-center">
                      Rs.
                      {cartItems.reduce(
                        (n, { offerPrice, qty }) => n + offerPrice * qty,
                        0
                      )}
                    </div>
                  </div>
                  <div className="bg-primary text-center cursor-pointer text-white rounded border-0 w-100 py-2 mt-5 josefin-sans-regular">
                    Proceed to Payment
                  </div>
                </>
              ) : (
                <div>
                  <h2 className="josefin-sans-light">Your cart is empty</h2>
                  <h4 className="josefin-sans-regular">Please add products</h4>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
