import { useNavigate } from "react-router-dom";

import honeyBanner from "../assets/images/honey-pot.png";
import one from "../assets/images/banner-img.png";
import about from "../assets/images/about.JPG";
import leftArrow from "../assets/images/arrow-left.png";
import rightArrow from "../assets/images/arrow-right.png";
import img1 from "../assets/images/img-1.png";
import img2 from "../assets/images/img-2.png";
import img3 from "../assets/images/img-3.png";
import img4 from "../assets/images/img-4.png";
import img5 from "../assets/images/img-5.png";
import img6 from "../assets/images/img-6.png";

export const HomePage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="banner_section layout_padding bg-warning">
        <div className="container-fluid">
          <div id="main_slider" className="carousel slide" data-ride="carousel">
            <div className="">
              <div className="carousel-item active">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="banner_taital_main">
                      <h1 className="banner_taital josefin-sans-regular">
                        100% NATURAL PRODUCTS
                      </h1>
                      <p className="banner_text josefin-sans-regular">
                        Pure Organic - No Artificial Additives
                      </p>
                      <div className="btn_main">
                        <div className="started_text">
                          <button onClick={() => navigate("/products")}>
                            Buy Now
                          </button>
                        </div>
                        <div className="started_text active">
                          <button onClick={() => navigate("/contact-us")}>
                            Contact Us
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="banner_img py-5">
                      <img src={honeyBanner} />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="carousel-item">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="banner_taital_main">
                      <h1 className="banner_taital">Fresh Vagetable Shop</h1>
                      <p className="banner_text">
                        Many variations of passages of Lorem Ipsum available,
                        but the majority have suffered
                      </p>
                      <div className="btn_main">
                        <div className="started_text">
                          <a href="#">Buy Now</a>
                        </div>
                        <div className="started_text active">
                          <a href="#">Contact Us</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="banner_img">
                      <img src={one} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="banner_taital_main">
                      <h1 className="banner_taital">Fresh Vagetable Shop</h1>
                      <p className="banner_text">
                        Many variations of passages of Lorem Ipsum available,
                        but the majority have suffered
                      </p>
                      <div className="btn_main">
                        <div className="started_text">
                          <a href="#">Buy Now</a>
                        </div>
                        <div className="started_text active">
                          <a href="#">Contact Us</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="banner_img">
                      <img src={one} />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            {/* <a
              className="carousel-control-prev"
              href="#main_slider"
              role="button"
              data-slide="prev"
            >
              <img src={leftArrow} />
            </a>
            <a
              className="carousel-control-next"
              href="#main_slider"
              role="button"
              data-slide="next"
            >
              <img src={rightArrow} />
            </a> */}
          </div>
        </div>
      </div>
      <div className="vagetables_section bg-clr py-3">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="vagetables_taital josefin-sans-semibold">
                Our Products
              </h1>
              {/* <p className="vagetables_text">
                Passages of Lorem Ipsum available, but the majority have
                suffered alteration{" "}
              </p> */}
            </div>
          </div>
          <div className="">
            <div className="row">
              <div className="col-md-4">
                <div className="hover01 column">
                  <figure>
                    <img src={img4} />
                  </figure>
                </div>
                <h3 className="harshal_text josefin-sans-regular">Cyrus</h3>
                <h3 className="rate_text josefin-sans-regular">$19</h3>
                {/* <div className="read_bt_1">
                  <a href="#">Read More</a>
                </div> */}
              </div>
              <div className="col-md-4">
                <div className="hover01 column">
                  <figure>
                    <img src={img5} />
                  </figure>
                </div>
                <h3 className="harshal_text josefin-sans-regular">Elianna</h3>
                <h3 className="rate_text josefin-sans-regular">$19</h3>
                {/* <div className="read_bt_1">
                  <a href="#">Read More</a>
                </div> */}
              </div>
              <div className="col-md-4">
                <div className="hover01 column">
                  <figure>
                    <img src={img6} />
                  </figure>
                </div>
                <h3 className="harshal_text josefin-sans-regular">Friedman</h3>
                <h3 className="rate_text josefin-sans-regular">$19</h3>
                {/* <div className="read_bt_1">
                  <a href="#">Read More</a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about_section layout_padding py-3">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="about_taital josefin-sans-regular">About Us</h1>
              {/* <p className="about_text">
                Passages of Lorem Ipsum available, but the majority have
                suffered alteration{" "}
              </p> */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="about_img breadcrumb">
                <img src={about} />
              </div>
            </div>
            <div className="col-md-6">
              {/* <div className="fresh_taital">Fresh any</div> */}
              <p className="ipsum_text josefin-sans-regular pt-5">
                We delve into the golden realm of organic honey – a nectareous
                elixir that not only sweetens our lives but also nourishes our
                bodies and souls. The journey into the world of organic honey
                isn't just about indulging in a sweet treat, it's a celebration
                of nature's purest gifts. As we savor the golden hues and
                diverse flavors, let us not only appreciate the labor of bees
                but also commit to preserving the delicate balance that allows
                us to enjoy this liquid gold. Join the organic honey revolution
                and let the sweet symphony of nature resonate in every drop.
                Emphasize our commitment to pesticide-free and organic farming
                practices.
              </p>
              <div className="read_bt">
                <button
                  className="bg-primary josefin-sans-regular"
                  onClick={() => navigate("/about-us")}
                >
                  Read More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
