import "../assets/css/harvest.css";

export const HarvestingPage = () => {
  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-12 mt-5">
          <h1 className="text-center josefin-sans-semibold">
            Our Honey-harvesting process
          </h1>
        </div>
      </div>
      <div className="mb-4">
        <div className="outer">
          <div className="harvest-card">
            <div className="info bg-white shadow-lg">
              <h5 className="title josefin-sans-semibold">
                Foraging for Nectar
              </h5>
              <p className="josefin-sans-regular">
                Worker bees, which are female bees, venture out from our hive in
                search of flowers containing nectar.
              </p>
              <p className="josefin-sans-regular">
                They use their long proboscis (tongue) to extract the nectar
                from the flowers.
              </p>
            </div>
          </div>
          <div className="harvest-card">
            <div className="info bg-white shadow-lg">
              <h5 className="title josefin-sans-semibold">Nectar Collection</h5>
              <p className="text-right josefin-sans-regular">
                Bees store the collected nectar in their specialized stomach
                called the "honey stomach" or "crop."
              </p>
              <p className="text-right josefin-sans-regular">
                The honey stomach can hold almost the same amount of nectar as
                the bee's own body weight.
              </p>
            </div>
          </div>
          <div className="harvest-card">
            <div className="info bg-white shadow-lg">
              <h5 className="title josefin-sans-semibold">
                Return to the Hive
              </h5>
              <p className="josefin-sans-regular">
                Once the honey bee's honey stomach is full, it returns to the
                hive to deliver the collected nectar.
              </p>
            </div>
          </div>
          <div className="harvest-card">
            <div className="info bg-white shadow-lg">
              <h5 className="title josefin-sans-semibold">
                Transfer of Nectar
              </h5>
              <p className="text-right josefin-sans-regular">
                Upon returning to the hive, the foraging bee regurgitates the
                nectar into the mouth of another worker bee.
              </p>
              <p className="text-right josefin-sans-regular">
                This process is repeated several times until the nectar is
                partially digested and has a higher concentration of enzymes.
              </p>
            </div>
          </div>
          <div className="harvest-card">
            <div className="info bg-white shadow-lg">
              <h5 className="title josefin-sans-semibold">
                Processing in the Hive
              </h5>
              <p className="josefin-sans-regular">
                Worker bees inside the hive continue the processing of the
                nectar by further breaking down complex sugars into simpler ones
                through enzymatic action.
              </p>
              <p className="josefin-sans-regular">
                The bees also add enzymes from their salivary glands to the
                nectar to initiate the conversion of sucrose into glucose and
                fructose.
              </p>
            </div>
          </div>
          <div className="harvest-card">
            <div className="info bg-white shadow-lg">
              <h5 className="title josefin-sans-semibold">
                Evaporation and Dehydration
              </h5>
              <p className="text-right josefin-sans-regular">
                The partially processed nectar, now known as "bee bread," is
                spread thinly over the cells of the honeycomb.
              </p>
              <p className="text-right josefin-sans-regular">
                Worker bees fan their wings over the honeycomb to facilitate
                evaporation, reducing the water content of the nectar to around
                17-20%.
              </p>
            </div>
          </div>
          <div className="harvest-card">
            <div className="info bg-white shadow-lg">
              <h5 className="title josefin-sans-semibold">Sealing the Cells</h5>
              <p className="josefin-sans-regular">
                Once the moisture content reaches the desired level, bees cap
                the cells with beeswax to seal the honey inside.
              </p>
              <p className="josefin-sans-regular">
                This sealed honeycomb serves as storage for the hive's food
                reserves.
              </p>
            </div>
          </div>
          <div className="harvest-card">
            <div className="info bg-white shadow-lg">
              <h5 className="title josefin-sans-semibold">Honey Harvesting</h5>
              <p className="text-right josefin-sans-regular">
                We carefully remove the honeycomb frames from the hive.
              </p>
              <p className="text-right josefin-sans-regular">
                The honeycomb is then uncapped to release the honey, which is
                collected and strained to remove any remaining debris or wax
                particles.
              </p>
            </div>
          </div>
          <div className="harvest-card">
            <div className="info bg-white shadow-lg">
              <h5 className="title josefin-sans-semibold">
                Bottling and Storage
              </h5>
              <p className="josefin-sans-regular">
                The harvested honey is bottled and stored in airtight containers
                to prevent crystallization and maintain freshness.
              </p>
              <p className="josefin-sans-regular">
                It is ready to be enjoyed as a delicious and nutritious natural
                sweetener!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
