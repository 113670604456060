import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

import "../assets/css/contact.css";

export const ContactPage = () => {
  return (
    <div className="contact_section py-5">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1 className="contact_taital josefin-sans-semibold">
              Get In Touch
            </h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="contact_section_2 pt-3">
          <div className="row">
            <div className="col-md-6">
              <div className="mail_section_1">
                <input
                  type="text"
                  className="mail_text josefin-sans-regular shadow-lg"
                  placeholder="Name"
                  name="Name"
                />
                <input
                  type="text"
                  className="mail_text josefin-sans-regular shadow-lg"
                  placeholder="Phone Number"
                  name="Phone Number"
                />
                <input
                  type="text"
                  className="mail_text josefin-sans-regular shadow-lg"
                  placeholder="Email"
                  name="Email"
                />
                <textarea
                  className="massage-bt p-3 h-100 josefin-sans-regular shadow-lg  "
                  placeholder="Your Message"
                  rows="5"
                  id="comment"
                  name="Message"
                ></textarea>
                <div className="send_bt float-right">
                  <button className="bg-primary josefin-sans-semibold">
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <h2 className="text-center josefin-sans-semibold">
                Our Branches
              </h2>
              <div className="bg-white shadow-lg p-4 m-4 text-center">
                <div className="icon d-flex align-items-center justify-content-center mb-3">
                  <FontAwesomeIcon icon={faLocationDot} />
                </div>
                <div className="text">
                  <span className="josefin-sans-regular">
                    Marathahalli, Bangalore, INDIA.
                  </span>
                </div>
              </div>
              <div className="bg-white shadow-lg p-4 m-4 text-center">
                <div className="icon d-flex align-items-center justify-content-center mb-3">
                  <FontAwesomeIcon icon={faLocationDot} />
                </div>
                <div className="text">
                  <span className="josefin-sans-regular">
                    Hyderabad, Telangana, INDIA.
                  </span>
                </div>
              </div>
              <div className="bg-white shadow-lg p-4 m-4 text-center">
                <div className="icon d-flex align-items-center justify-content-center mb-3">
                  <FontAwesomeIcon icon={faLocationDot} />
                </div>
                <div className="text">
                  <span className="josefin-sans-regular">
                    Guntur, Andhra Pradesh, INDIA.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
