import about from "../assets/images/about.JPG";

export const AboutPage = () => {
  return (
    <>
      {/* <div className="container-fluid"> */}
      <div className="about_section py-5 bg-transparent">
        <div className="container-fluid px-5">
          {/* <div className="row">
              <div className="col-md-12 text-center">
                <h1 className="josefin-sans-bold">OUR MISSION</h1>
                <p className="about_text">
                  Passages of Lorem Ipsum available, but the majority have
                  suffered alteration{" "}
                </p>
              </div>
            </div> */}
          <div className="row">
            <div className="col-md-6">
              <div className="about_img breadcrumb justify-content-center">
                <img src={about} alt="about-img" />
              </div>
            </div>
            <div className="col-md-6 align-content-center">
              {/* <div className="fresh_taital">Fresh any</div> */}
              <h1 className="josefin-sans-bold fa-3x pt-3">OUR MISSION</h1>
              <p className="ipsum_text josefin-sans-regular pt-3">
                We delve into the golden realm of organic honey – a nectareous
                elixir that not only sweetens our lives but also nourishes our
                bodies and souls. The journey into the world of organic honey
                isn't just about indulging in a sweet treat, it's a celebration
                of nature's purest gifts. As we savor the golden hues and
                diverse flavors, let us not only appreciate the labor of bees
                but also commit to preserving the delicate balance that allows
                us to enjoy this liquid gold. Join the organic honey revolution
                and let the sweet symphony of nature resonate in every drop.
                Emphasize our commitment to pesticide-free and organic farming
                practices.
              </p>
              {/* <div className="read_bt">
                  <a href="#">Read More</a>
                </div> */}
            </div>
          </div>
          <div className="row align-items-center pt-5">
            <div className="col-lg-12">
              <div>
                <h2 className="josefin-sans-semibold text-center fa-2x">
                  OUR PROMISE - From Hive to Home: Our Organic Honey's Journey,
                  Unveiled"
                </h2>
                <p className="josefin-sans-regular text-dark">
                  At our <b>Aranyaka</b>, we believe in more than just
                  delivering honey to your doorstep; we're passionate about
                  sharing the incredible journey our organic honey takes – from
                  flourishing farms to your homes. Join us as we unravel the
                  story behind every drop, ensuring transparency, purity, and a
                  connection to the source.
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center pt-5">
            <div className="col-lg-12">
              <div className="text-dark">
                <h2 className="josefin-sans-semibold text-center fa-2x">
                  Our Commitment
                </h2>
                <p className="josefin-sans-regular">
                  The dedicated farmers and beekeepers form the backbone of our
                  honey-making process.
                </p>
                <p className="josefin-sans-regular">
                  The careful and respectful harvesting methods we employ,
                  ensure minimal disruption to our industrious bee colonies.
                </p>
                <p className="josefin-sans-regular">
                  our commitment to preserving the natural harmony of the
                  beekeeping process.
                </p>
                <p className="josefin-sans-regular">
                  The artisanal approach in extracting honey, where each step is
                  carried out by skilled hands to preserve the integrity and
                  purity of the final product.
                </p>
                <p className="josefin-sans-regular">
                  Our dedication is embedded in the craft, from hive to jar.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};
